.player{
  max-width: 600px;
  background-color: rgb(246, 228, 254);
  border-radius: 12px;
  border: 1px solid gray;
  margin-left: 0px;
}
.tcount{
  font-weight: 700;
}
body{
  background-image: url('../public/images/bgpattern.jpg');
  background-repeat: repeat;
}
.inputform{
  max-width: 600px;
  background-color: rgb(255, 224, 184);
  border-radius: 20px;
  padding: 20px;
}
.menuitem{
  width:170px;
  margin-left: 10px;
}
.menuitem:hover{
  border: 2px solid white;
}
.adframe{
  width: 600px;
  background-color:rgba(0, 0, 0, 0.4);
  color: aliceblue;
  border-radius: 15px;
}
.adcomp{
  width: 600px;
  background-color: rgba(0, 0, 0, 0.4);
}
.groupframe{
  color: aliceblue;
  width: 600px;
  background-color: rgb(59, 59, 59);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 15px;
}
.groupframe p{
  color: beige;
  margin-top: 2px;
  margin-bottom: 2px;
}
.playerframe{
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(229, 138, 19);
  border-radius: 10px;
  margin-bottom: 5px;
}
.groupname, .addplayerframe{
  border: 2px solid #FFF;
  border-radius: 30px;
  padding: 20px;
  font-weight: 600;
}
.logocontainer{
  
  height: 700px;
  width: 100%;
  padding: 10px;
  border: 2px solid #FFF;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.75);
  background-size: cover;
}
.mysubmit
{
  max-width: 300px;
  height: 35px;
  margin-top: 150px;
}
.thelogin{
  width: 100%;
  display: flex;
  justify-content: center;
}
